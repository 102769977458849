// Home.js
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
`;

const LoginForm = styled.form`
  width: 300px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const FormGroup = styled.label`
  display: block;
  margin-bottom: 15px;
  font-size: 16px;
`;

const InputField = styled.input`
  width: 65%;
  padding: 8px;
  margin-top: 5px;
  font-size: 14px;

  &:focus {
    outline: none;
  }
`;
const LoginButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 10px;
`;

const TextButton = styled.button`
  background: none;
  border: none;
  color: #4caf50;
  cursor: pointer;
  font-size: 14px;
`;

const Home = () => {
  const navigate = useNavigate(); // Initialize useNavigate
  const [formData, setFormData] = useState({ email: '', password: '' });

  const isMobileDevice = () => {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  };

  useEffect(() => {
    if (isMobileDevice()) {
      toast.info('모바일에서 화면이 깨질 수 있습니다. 가능한 PC 화면 또는 넓은 화면에서 접속해 주세요.', { position: 'top-center' });
    }
  }, [navigate]); // Empty dependency array ensures the effect runs only once after mounting

  const handleLogin = async () => {
    try {
      const response = await fetch('/api/users/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const data = await response.json();
        const token = data.token || null;
        const username = data.username || null;
        const nickname = data.nickname || null;

        if(!token || !username || !nickname) {
          console.error('Invalid token, username, or nickname');
          return;
        }

        // Save the token, username, and nickname to localStorage or a secure storage mechanism
        localStorage.setItem('token', token);
        localStorage.setItem('username', username);
        localStorage.setItem('nickname', nickname);

        // Navigate to /myboard
        navigate('/myboard');
      } else {
        // Display an error message based on the response status
        if (response.status === 401) {
          toast.error('Invalid username or password. Please try again.', { position: 'top-center' });
        } else {
          toast.error('Failed to log in. Please try again later.', { position: 'top-center' });
        }
        console.error('Failed to log in');
      }
    } catch (error) {
      console.error('Error during login:', error);
    }
  };


  const handleKeyDown = (e) => {
    // Check if Enter key is pressed
    if (e.key === 'Enter') {
      e.preventDefault();
      handleLogin();
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleRegister = () => {
    // Redirect to the /register URL using useNavigate
    navigate('/register');
  };
  
  const handleResetPassword = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
  
    // Display a modal toast message
    toast.dark('기능 개발중 입니다. 급하게 암호를 잊어버리셨거나 변경을 원하시는 경우, 서버관리자에게 문의 부탁드립니다.', {
      position: 'top-center',
      autoClose: 10000, // 5 seconds
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleHowToUse = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
  
    // // Display a modal toast message
    // toast.dark('기능 개발중 입니다', {
    //   position: 'top-center',
    //   autoClose: 10000, // 5 seconds
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    // });

    navigate('/howtouse');
  };

  return (
    <HomeContainer>
      <h3 style={{margin: 0}}>WhyQ 아카데미</h3>
      <h1 style={{margin: 0, marginBottom: 10}}>융합보드</h1>
      <LoginForm>
        <FormGroup>
          <span style={{ width: '75px', display: 'inline-block' }}>email:</span>
          <InputField type="text" name="username" onChange={handleChange} onKeyDown={handleKeyDown} />
        </FormGroup>
        <FormGroup>
          <span style={{ width: '75px', display: 'inline-block' }}>password:</span>
          <InputField type="password" name="password" onChange={handleChange} onKeyDown={handleKeyDown} />
        </FormGroup>
        <LoginButton type="button" onClick={handleLogin}>
          Login
        </LoginButton>
        
      </LoginForm>

      <div style={{ textAlign: 'center', marginTop:'20px' }}>
        <TextButton onClick={handleRegister}>가입하기</TextButton> |{' '}
        <TextButton onClick={handleResetPassword}>비밀번호 재설정</TextButton> |{' '}
        <TextButton onClick={handleResetPassword}>비밀번호 분실</TextButton> |{' '}
        <TextButton onClick={handleHowToUse}>사용법</TextButton>
      </div>
      <div>
      {
        isMobileDevice()? <div style={{margin: '20px', color: 'red'}}>모바일에서 화면이 깨질 수 있습니다. 가능한 PC 화면 또는 넓은 화면에서 접속해 주세요.</div> : null
      }
      </div>
    </HomeContainer>
  );
};

export default Home;
