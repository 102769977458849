// App.js
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ConvTable from './ConvTable';
import Sidebar from './Sidebar'; // Import the Sidebar component
import { ToastContainer } from 'react-toastify';
import { fetchInitialKeywords } from './redux/actions';
import 'react-toastify/dist/ReactToastify.css';

const containerStyle = {
  display: 'flex',
  width: '100%',
  maxWidth: 'calc(100% - 20px * 2)',
  margin: '20px',
  border: '1px solid #ddd',
  overflowY: 'auto',
};

const contentStyle = {
  flex: '1', // Flexible width for the main content
};

const buttonStyle = {
  outline: 'none', // Make the outline invisible
  border: 'none',  // Remove border
  background: '#e0e0e0', // Subtle background color
  color: '#333', // Text color
  padding: '5px 10px', // Padding for better appearance
  cursor: 'pointer', // Add cursor pointer on hover
};

const App = () => {
  const dispatch = useDispatch();
  const [isSidebarOpen, setSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <div style={containerStyle}>
      <Sidebar isOpen={isSidebarOpen} />
      <div style={contentStyle}>
        {/* Hamburger button to toggle sidebar visibility */}
        <button onClick={toggleSidebar} style={buttonStyle}>
          ☰
        </button>
        <ConvTable />
        {/* <ToastContainer position="bottom-right" autoClose={5000} /> */}
      </div>
    </div>
  );
};

export default App;
