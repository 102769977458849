import React, { useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';

const RegisterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const RegisterForm = styled.form`
  width: 300px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const FormGroup = styled.label`
  display: block;
  margin-bottom: 15px;
  font-size: 16px;
`;

const InputField = styled.input`
  width: 90%;
  padding: 8px;
  margin-top: 5px;
  font-size: 14px;
`;

const RegisterButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
`;

const helpTextStyles = {
  fontSize: '0.7em',
};

const Register = () => {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    confirmPassword: '',
    nickname: '',
  });

  const isEmailValid = (email) => {
    // Use a regular expression to validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isPasswordComplexEnough = (password) => {
    // Add your complexity requirements for the password
    // For example, you can check minimum length, presence of numbers, special characters, etc.
    return password.length >= 6; // Sample: Minimum length is 6 characters
  };

  const handleRegister = async () => {
    const { username, password, confirmPassword, nickname } = formData;

    // Check if any field is empty
    if (!username || !password || !confirmPassword || !nickname) {
      toast.error('All fields are required', { position: 'top-center' });
      return;
    }

    // Validate email format
    if (!isEmailValid(username)) {
      toast.error('Invalid email format', { position: 'top-center' });
      return;
    }

    // Validate password complexity
    if (!isPasswordComplexEnough(password)) {
      toast.error('Password should be at least 6 characters long', { position: 'top-center' });
      return;
    }

    // Check if passwords match
    if (password !== confirmPassword) {
      toast.error('Passwords do not match', { position: 'top-center' });
      return;
    }

    // Proceed with registration logic
    try {
      const response = await fetch('/api/users/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        console.log('User registered successfully!');
        // Redirect to login or another page after successful registration
        toast.success('가입이 정상적으로 이루어졌습니다.', { position: 'top-center' });
        toast.success('로그인화면에서 입력하신 정보로 로그인하세요.', { position: 'top-center' });
        toast.success('로그인화면으로 가는중...', { position: 'top-center' });

        // Wait for 5 seconds and then navigate to the login page
        setTimeout(() => {
          window.location.href = '/login'; // Navigate to the login page
        }, 5000);
      } else {
        console.error('Failed to register user');
        toast.error('Failed to register user. Please try again.', { position: 'top-center' });
      }
    } catch (error) {
      console.error('Error during registration:', error);
      toast.error('An error occurred during registration. Please try again later.', { position: 'top-center' });
    }
  };

  const handleKeyDown = (e) => {
    // Check if Enter key is pressed
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent default form submission behavior
      handleRegister(); // Trigger registration
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <RegisterContainer>
      <h1>Register</h1>
      <RegisterForm>
        <FormGroup>
          이메일:
          <InputField type="text" name="username" value={formData.username} onChange={handleChange} />
        </FormGroup>
        <FormGroup>
          비밀번호:
          <InputField type="password" name="password" value={formData.password} onChange={handleChange} />
        </FormGroup>
        <FormGroup>
          비밀번호 확인:
          <InputField
            type="password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          닉네임 (별명):
          <InputField type="text" name="nickname" value={formData.nickname} onChange={handleChange} />
        </FormGroup>
        <div style={helpTextStyles}>
          <span>* 입력하신 비밀번호는 암호화되어 저장됩니다.</span>
        </div>
        <div style={helpTextStyles}>
          <span>* 이메일은 사용자식별을 위해 사용됩니다.</span>
        </div>
        <br />
        <RegisterButton type="button" onClick={handleRegister}>
          가입하기
        </RegisterButton>
      </RegisterForm>
    </RegisterContainer>
  );
};

export default Register;
