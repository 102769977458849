// HowToUse.js
import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import img_example_keywords from './assets/images/example_keywords.png';

const HowToUse = () => {
  const isAIMode = useSelector((state) => state.isAIMode);

  const sectionStyle = {
    marginLeft: '20px', // Add left margin
    marginBottom: '4em', // Add bottom margin
  };
  const exampleImageStyle = {
    maxWidth: '400px',
    width: '100%',
    height: 'auto',
  };

  return (
    <div style={{marginLeft: '20px', paddingRight:'20px'}}>
      <section style={{marginLeft: '0px', marginTop: '10px'}}>
        {/* 뒤로가기 버튼 */}
        <Link to="/myboard" style={{ textDecoration: 'none', color: 'green', marginBottom: '10px', display: 'block' }}>
          뒤로가기
        </Link>
      </section>


      <h1>프로그램 설명</h1>
      <section style={sectionStyle}>
        <p>
        WhyQ Academy 융합훈련을 위한 융합보드 프로그램입니다.<br/>
        {isAIMode && (<div>분해하고자 하는 대상을 추가하여 직접 분해하거나 AI의 도움을 받을 수 있습니다.<br/></div>)}
        분해가 완료된 대상들은 다양한 조합으로 융합하여 새로운 아이디어를 제안할 수 있습니다.<br/>
        <br/>
        원석연 교수님께서 고안하신 분해 및 융합훈련에 감명받아,<br/>
        융합훈련 수강생분들께서 보다 용이하게 아이디어를 도출하고 기록할 수 있는 도구를 만들어보고자 했습니다.<br/>
        부족한 프로그램이지만, 창의적인 융합 아이디어를 만드시는 데 도움이 되기를 바랍니다.<br/>
        </p>
      </section>
      
      <h1>사용 방법</h1>
      <section style={sectionStyle}>
        <h2>나의 분해대상</h2>
        <p>
          사이드바에는 여러 가지 버튼이 있어 분해대상을 관리할 수 있습니다. '나의 분해대상' 에 있는 각 버튼의 기능은 다음과 같습니다.
        </p>
        <ul>
          <li>
            <strong style={{ fontSize: '1.2em' }}><span style={{marginRight: '5px'}}>➕</span> 추가 버튼:</strong> 분해대상을 추가하기 위해 이 버튼을 클릭하세요. 입력창에 새로운 분해대상의 이름을 입력한 후 '확인'을 누르세요.
          </li>
          <li>
            <strong style={{ fontSize: '1.2em' }}><span style={{marginRight: '5px'}}>🗑️</span> 삭제 버튼:</strong> 생성한 분해대상을 삭제하려면 이 버튼을 클릭하고, 분해대상을 클릭하세요.
          </li>
          <li>
            <strong style={{ fontSize: '1.2em' }}><span style={{marginRight: '5px'}}>✏️</span> 이름 바꾸기 버튼:</strong> 생성한 분해대상의 이름을 바꾸려면 이 버튼을 클릭하고, 분해대상을 클릭하세요. 입력창에 새 이름을 입력한 후 '확인'을 누르세요.
          </li>
          <li>
            <strong style={{ fontSize: '1.2em' }}><span style={{marginRight: '5px'}}>🎨</span> 색상 바꾸기 버튼:</strong> 생성한 분해대상의 색상을 바꾸려면 이 버튼을 클릭하고, 분해대상을 클릭하세요. 색상이 랜덤하게 바뀝니다(파스텔톤 색상만 가능하며, 특정 색상을 지정하는 기능은 현재 지원하지 않습니다).
          </li>
          {isAIMode && (<li>
            <strong style={{ fontSize: '1.2em' }}><span style={{fontWeight: 'bold', marginRight: '5px'}}> AI</span> 분해 버튼:</strong> 인공지능(ChatGPT)을 통해 자동으로 분해 대상을 분해해 보세요. 버튼 클릭 후 잠시 기다리시면 인공지능이 여러분 대신 분해보드를 채워드립니다
          </li>
          )}
        </ul>
      </section>

      <section style={sectionStyle}>
        <h2>분해대상 리스트</h2>
        <p>
          '➕ 추가 버튼'을 누르고 분해대상을 추가하면 '나의 분해대상'에 나만의 분해대상 리스트가 생성됩니다. <br/>
          분해대상을 클릭하면 해당 분해대상을 '선택된 분해대상' 에 추가하여 분해 및 융합 작업을 수행할 수 있습니다.<br/>
        </p>
        <p style={{fontWeight:'bold'}}>(예시)</p>
        {/* <img src="asdf"></img> */}
        <img src={img_example_keywords} style={exampleImageStyle} alt="keywords images as examples" />
      </section>

      <section style={sectionStyle}>
        <h2>나의 융합 아이디어</h2>
        <p>
        작성한 융합 아이디어들은 여기에 저장됩니다. 융합 아이디어를 작성한 경우에만 리스트가 생성됩니다.<br/>
        융합 아이디어를 클릭하면 '선택된 분해대상'의 항목들이 융합 아이디어의 분해대상 항목들로 교체됩니다.<br/>
        <br/>
        유의 : 분해대상 리스트에서 제거 하더라도, '나의 융합 아이디어' 에서 사라지지 않도록 했습니다. 오류가 아니에요!<br/>
        </p>
      </section>

      <section style={sectionStyle}>
        <h2>선택된 분해대상</h2>
        <p>
        사이드바에서 분해대상 버튼을 클릭하면 이곳에 분해대상이 보입니다.<br/>
        '선택된 분해대상' 에 있는 분해대상 버튼을 누르면 다시 사라집니다.<br/>
        </p>
      </section>
      
      <section style={sectionStyle}>
        <h2>분해 카드</h2>
        <p>
          '선택된 분해대상' 에 해당하는 분해대상의 분해 카드들이 여기에 보입니다.<br/>
          카드를 선택하시면 분해 카드의 내용을 편집할 수 있습니다<br/>
        </p>
        <ul>
          <li>
            <strong>요소(물질/특성):</strong> 분해대상을 구성하는 요소는 무엇이 있는지 공학적으로 분석한 내용을 적어보세요.
          </li>
          <li>
            <strong>용도(기능/쓰임새):</strong> 분해대상는 어떤 용도로 사용할 수 있는지 공학적으로 분석한 내용을 적어보세요.
          </li>
          <li>
            <strong>효과(효용가치):</strong> 분해대상를 활용하면 발생하는 효과를 인문학적으로 분석한 내용을 적어보세요.
          </li>
          <li>
            <strong>의미(본질가치):</strong> 분해대상의 본질적인 의미를 인문학적으로 분석한 내용을 적어보세요.
          </li>
        </ul>
        <p>
          Enter키를 입력하시거나 다른 곳을 클릭하면 편집중이던 내용이 저장됩니다<br/>
          Shift+Enter를 입력하시면 줄 바꿈을 할 수 있습니다<br/>
        </p>
      </section>

      <section style={sectionStyle}>
        <h2>융합 아이디어</h2>
        <p>
        분해대상들을 다양한 조합으로 융합하여 새로운 가치를 창출하고 생각의 충돌을 즐겨보세요.<br/>
        Enter키를 입력하시거나 다른곳을 클릭하면 편집중이던 내용이 저장됩니다<br/>
        Shift+Enter를 입력하시면 줄바꿈을 할 수 있습니다<br/>
        </p>
      </section>

      <section style={sectionStyle}>
        <h2>데이터 다운로드</h2>
        <p>
        작성하신 분해 및 융합 데이터를 파일로 다운받을 수 있습니다.<br/>
        '스크린샷'을 화면 전체의 스크린샷을 이미지로 다운로드 합니다.<br/>
        '엑셀다운'을 누르면 전체 데이터를 다운로드 합니다.<br/>
        </p>
      </section>

      <h1>만든이</h1>
      <section style={sectionStyle}>
      <ul>
        <li>
          <strong>Dr. Won, Seok-Yeon:</strong> 융합훈련 개념 창시자.
        </li>
        <li>
          <strong>GQPHD:</strong> 프로그램 개발 및 운영.
        </li>
      </ul>
      </section>


      <h1>기타</h1>
      <section style={sectionStyle}>

      <h2>유의사항</h2>
      <ul>
        <li>
          본 프로그램은 비영리 목적으로 만들어졌습니다.
        </li>
        <li>
          본 프로그램은 개인식별정보를 수집하지 않습니다.
        </li>
        <li>
          본 프로그램에서 수집된 이메일은 사용자식별 목적 이외에 다른 목적으로 사용하지 않습니다.
        </li>
        <li>
          분해 내용은 프로그램 개선 목적을 위하여 사용될 수 있으며, 사용자의 동의 없이 공개되지 않습니다.
        </li>
        <li>
          본 프로그램은 모바일에서의 사용을 고려하지 않고 만들었습니다. 가능하면 PC에서 사용해 주세요.
        </li>
        <li>
          본 프로그램은 대용량 트래픽을 고려하여 설계되지 않았습니다. 가끔 느릴 수 있어요...
        </li>
        <li>
          서버 공격하지 마세요... 건드리면 터져요...
        </li>
      </ul>

      <h2>수정이력</h2>
      <ul>
        <li>
          <strong>version 1.3 : </strong> 데이터 다운로드 기능 버그수정. 도메인분리. (2024/06/02)
        </li>
        <li>
          <strong>version 1.2 : </strong> 데이터 다운로드 기능 추가. 오탈자 수정. 사용법 문서 내용 추가. (2024/02/20)
        </li>
        <li>
          <strong>version 1.1 : </strong> 향상된 분해 기능 추가 (2024/02/19)
        </li>
        <li>
          <strong>version 1.0 : </strong> 최초 배포 버전 (2024/02/18)
        </li>
      </ul>

      <h2>문의하기</h2>
      <p>
        프로그램 문의사항은 아래 이메일로 문의 바랍니다.<br/>
        이메일: <a href="mailto:sikbrad@gmail.com">sikbrad@gmail.com</a><br/>
      </p>
      </section>


      
      <br></br>
      <br></br>
      <section style={{marginLeft: '0px', marginTop: '10px'}}>
        {/* 뒤로가기 버튼 */}
        <Link to="/myboard" style={{ textDecoration: 'none', color: 'green', marginBottom: '10px', display: 'block' }}>
          뒤로가기
        </Link>
      </section>
    </div>
  );
};

export default HowToUse;
