// ConvRes.js
import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteConvRes, fetchCombinations, fetchConvRes, setConvRes, saveConvRes } from './redux/actions';
import { toast } from 'react-toastify';

const ConvRes = () => {
  const dispatch = useDispatch();
  const keywords = useSelector((state) => state.keywords);
  const convRes = useSelector((state) => state.convRes);

  const [isEditing, setEditing] = useState(false);
  const [editedText, setEditedText] = useState(convRes);
  const textareaRef = useRef(null); // Create a ref for the textarea


  const DefaultConvText = "(융합 아이디어를 입력해주세요)";
  const InvalidConvText = "(2개 이상의 분해대상을 선택해주세요.)";
  
  const getKeywordIds = () => {
    // Sort keywords by id in ascending order
    const sortedKeywords = keywords.sort((a, b) => a.id - b.id);

    // Get an array of keyword names sorted by id
    const sortedIds = sortedKeywords.map((keyword) => keyword.id);

    // Concatenate sorted keyword names with '-'
    let ids = sortedIds.join('-');
    return ids;
  }

  useEffect(() => {
    if (isEditing) {
      // Focus on the textarea when entering editing mode
      if(!textareaRef.current) return;
      textareaRef.current.focus();

      // Move the cursor to the end of the textarea
      if(!editedText) return;

      if(editedText == DefaultConvText || editedText == InvalidConvText){
        setEditedText(""); // Clear the DefaultConvText
      }else{
        const length = editedText?.length || 0;
        textareaRef.current.setSelectionRange(length, length);

        adjustTextareaHeight();
      }
    }
  }, [isEditing, editedText]);


  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto'; // Reset the textarea's height
      textarea.style.height = textarea.scrollHeight + 'px'; // Set new height
    }
  }

  // Update editedText when isEditing becomes true
  useEffect(() => {
    if (isEditing) {
      setEditedText(convRes);
    }
  }, [isEditing, convRes]);

  useEffect(() => {
    // setConvRes("basdasd");
    
    // Dispatch the asynchronous action
    dispatch(fetchConvRes(getKeywordIds())).then((success) => {
      if (success) {
        console.log("fetch keywords success");
      } else {
        // dispatch(setConvRes('DefaultConvText'));
        // Clear the ConvRes
        if(keywords.length < 2){
          dispatch(setConvRes(InvalidConvText)); // Clear the ConvRes
        }else{
          dispatch(setConvRes(DefaultConvText));
        }
      }
    });

  }, [dispatch, keywords]);

  const cardStyle = {
    padding: '10px',
    margin: '10px',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', // Soft shadow effect
    cursor: 'pointer',
  };

  const titleStyle = {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '10px',
    justifyContent: 'flex-start',
  };

  const h2Style = {
    marginRight: '10px',
  };

  const spanStyle = {
    backgroundColor: '#f4f4f4', // Grey background color
    padding: '5px', // Adjust padding as needed
    borderRadius: '5px', // Adjust border radius as needed
    whiteSpace: 'pre-line',
  };

  const handleSingleClick = () => {
    setEditing(true);
  };

  const handleBlur = () => {
    setEditing(false);
    // dispatch(setConvRes(editedText));

    // if(editedText == DefaultConvText){
    if (editedText == convRes || editedText == DefaultConvText) {
      console.log("ConvRes.js handleBlur editedText == DefaultConvText, skips.")
      return;
    }

    if (keywords.length < 2) {
      console.log("keywords.length < 2, skips.");
      return;
    }

    // Check if the text has actually changed
    if (editedText !== convRes && editedText !== DefaultConvText) {
      if (editedText.trim() === '') {
        // If editedText is an empty string, delete convres data
        dispatch(deleteConvRes(getKeywordIds())).then((success) => {
          if (success) {
            // toast.success('ConvRes for this combination deleted');
            toast.success(`융합 아이디어가 삭제되었습니다`);

            // Fetch existing convres keys when the component mounts
            dispatch(setConvRes(DefaultConvText));
            dispatch(fetchCombinations());
          } else {
            toast.error('Failed to delete result');
          }
        });
      } else {
        dispatch(saveConvRes(getKeywordIds(), editedText)).then((content) => {
          if (content) {
            // toast.success(`ConvRes updated and saved`);
            toast.success(`융합 아이디어가 저장되었습니다`);

            // Fetch existing convres keys when the component mounts
            dispatch(setConvRes(content));
            dispatch(fetchCombinations());
          } else {
            toast.error('Failed to update and save result. Make sure to select at least two keywords.');
          }
        });
      }
    } else {
      console.log("convres not changed. skips updating.");
    }
  };

  const handleChange = (e) => {
    setEditedText(e.target.value);
  };

  const handleKeyDown = (e) => {
    // Check if Enter key is pressed without Shift
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault(); // Prevents adding a newline
      handleBlur(); // Calls the handleBlur function
    } else if (e.key === 'Enter' && e.shiftKey) {
      // Insert a new line character
      setEditedText((prevText) => prevText);
    }
  };

  return (
    <div style={cardStyle} onClick={handleSingleClick}>
      {isEditing ? (
        <div style={titleStyle}>
          <h2 style={h2Style}>융합 아이디어</h2>
          <textarea
            ref={textareaRef}
            value={editedText}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            style={{ ...spanStyle, width: '100%', minHeight: '60px' }}
          />
        </div>
      ) : (
        <div style={titleStyle}>
          <h2 style={h2Style}>융합 아이디어</h2>
          <span style={spanStyle}>{convRes}</span>
        </div>
      )}
    </div>
  );
};

export default ConvRes;
