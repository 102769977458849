// DecompTable.js
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTable } from 'react-table';
import DecompCard from './DecompCard';

const DecompTable = () => {
  const keywords = useSelector((state) => state.keywords);

  // Memoize the columns definition
  const columns = useMemo(
    () => [
      { Header: '요소(물질/특성)', accessor: 'element' },
      { Header: '용도(기능/쓰임새)', accessor: 'usage' },
      { Header: '효과(효용가치)', accessor: 'effect' },
      { Header: '의미(본질가치)', accessor: 'meaning' },
    ],
    []
  );

  // Memoize the data
  const data = useMemo(() => {
    return keywords.map((keyword, idxKeyword) => ({
      element: <DecompCard keywordIndex={idxKeyword} cellIndex={0} />,
      usage: <DecompCard keywordIndex={idxKeyword} cellIndex={1} />,
      effect: <DecompCard keywordIndex={idxKeyword} cellIndex={2} />,
      meaning: <DecompCard keywordIndex={idxKeyword} cellIndex={3} />,
    }));
  }, [keywords]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  useEffect(() => {
    // Perform any additional updates or side effects here
  }, [keywords]);

  return (
    <table {...getTableProps()} style={{ width: 'calc(100%)', borderCollapse: 'collapse', overflowY: 'auto' }}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()} style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left', background: '#f2f2f2' }}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()} style={{ border: '1px solid #ddd', padding: '8px' }}>
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default DecompTable;
