
// export const getSubdomain = () => {
//     const hostname = window.location.hostname;
//     const parts = hostname.split('.');
//     if (parts.length > 2) {
//       return parts.slice(0, -2).join('.');
//     }
//     return null;
//   };

export const calcAiEnabled = () => {
  const subdomain = window.location.hostname.split('.')[0];
  const isAIMode = subdomain === 'conv-auto';
    // const subdomain = getSubdomain();
    // // if(subdomain.indexOf("auto") >= 0){
    // if(subdomain.indexOf("conv-auto") >= 0){
    //   console.log("AI is enabled");
    //     return true;
    // }
    // console.log("AI is disabled");
    // return false;

    return isAIMode;
}

