// KeywordTitle.js
import React from 'react';
import { useSelector } from 'react-redux';
import KeywordButton from './KeywordButton';

const KeywordTitle = () => {
  const keywords = useSelector((state) => state.keywords);

  const titleStyle = {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '10px',
    justifyContent: 'flex-start',
  };

  const h3Style = {
    marginRight: '10px',
  };

  return (
    <div style={titleStyle}>
      <h3 style={h3Style}>선택된 분해대상</h3>
      {keywords.map((keyword, index) => (
        <KeywordButton key={keyword.id} keyword={keyword} keywordsMode={"remove"} />
      ))}
    </div>
  );
};

export default KeywordTitle;
