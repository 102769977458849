// In a separate file, e.g., GlobalStyles.js
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'gowundodum', sans-serif;
  }


  button {
    font-family: 'gowundodum', sans-serif;
  }

  /* Add more global styles as needed */
`;

export default GlobalStyle;