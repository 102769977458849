// actions.js
import { useDispatch } from 'react-redux';

export const addKeywordToTitle = (keywordId) => ({
  type: 'ADD_KEYWORD_TO_TITLE',
  payload: keywordId,
});

export const removeKeywordFromTitle = (keywordId) => ({
  type: 'REMOVE_KEYWORD_FROM_TITLE',
  payload: keywordId,
});

export const updateKewyword = (keywordId) => ({
  type: 'UPDATE_KEYWORD',
  payload: keywordId,
});

export const setConvRes = (convRes) => ({
  type: 'SET_CONV_RES',
  payload: convRes,
});

export const editDecomp = (keywordId, cellIndex, newText) => {
  return {
    type: 'EDIT_DECOMP',
    payload: {
      keywordId,
      cellIndex,
      newText,
    },
  };
};

export const setKeywordColor = (keywordId, color) => ({
  type: 'SET_KEYWORD_COLOR',
  payload: {
    keywordId,
    color,
  },
});

export const setOriginalKeywordColor = (keywordId, color) => ({
  type: 'SET_ORIGINAL_KEYWORD_COLOR',
  payload: {
    keywordId,
    color,
  },
});


export const setKeywordName = (keywordId, newName) => ({
  type: 'SET_KEYWORD_NAME',
  payload: {
    keywordId,
    name: newName
  },
});


export const setOriginalKeywordName = (keywordId, newName) => ({
  type: 'SET_ORIGINAL_KEYWORD_NAME',
  payload: {
    keywordId,
    name: newName
  },
});


// Action creator for updating keywords
export const updateKeywords = (keyIds) => (dispatch, getState) => {
  // Get the originalKeywords from the current state
  const originalKeywords = getState().originalKeywords;

  // Extract the keywords based on keyIds
  const updatedKeywords = originalKeywords.filter((keyword) =>
    keyIds.includes(keyword.id.toString())
  );
  
  console.log("updatedKeywords", updatedKeywords);
  // Dispatch the action to update the keywords
  dispatch({
    type: 'UPDATE_KEYWORDS',
    payload: updatedKeywords,
  });
};

export const removeOriginalKeyword = (keywordId) => ({
  type: 'REMOVE_ORIGINAL_KEYWORD',
  payload: keywordId,
});

export const _setOriginalKeywordEnabled = (keywordId, enabled) => ({
  type: 'SET_ORIGINAL_KEYWORD_ENABLED',
  payload: {
    keywordId,
    enabled,
  },
});

// actions.js
export const setOriginalKeywordEnabled = (keywordId, enabled) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`/api/keywords/toggle/${keywordId}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ enabled }),
      });

      if (!response.ok) {
        throw new Error('Failed to toggle keyword status');
      }

      // Dispatch an action to update the enabled status in the Redux store
      dispatch(_setOriginalKeywordEnabled(keywordId, enabled));

      return true;
    } catch (error) {
      console.error('Error toggling keyword status:', error);
      // Handle the error as needed
      return false;
    }
  };
};


export const updateOriginalKeywords = (updatedOriginalKeywords) => ({
  type: 'UPDATE_ORIGINAL_KEYWORDS',
  payload: updatedOriginalKeywords,
});

export const addKeyword = (newKeywordObject) => ({
  type: 'ADD_KEYWORD',
  payload: newKeywordObject,
});

export const addNewKeyword = (newKeywordObject) => {
  return async (dispatch) => {
    try {
      const response = await fetch('/api/keywords/add', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newKeywordObject),
      });

      if (!response.ok) {
        throw new Error('Failed to add a new keyword');
      }

      const data = await response.json();

      console.log("data", data);

      // Dispatch an action to update the originalKeywords in the Redux store
      // dispatch(addKeyword(newKeywordObject));
      // dispatch(updateOriginalKeywords([...originalKeywords, data])); // Assuming the server responds with the updated list

      

      return true; // Indicate success
    } catch (error) {
      console.error('Error adding a new keyword:', error);
      // Handle the error as needed
      return false; // Indicate failure
    }
  };
};

export const fetchInitialKeywords = () => {
  return async (dispatch) => {
    try {
      // Use a relative URL without the port
      const response = await fetch('/api/keywords/all', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.json();

      const formattedKeywords = data.map((keyword) => ({
        id: keyword.id,
        name: keyword.name,
        decomps: [keyword.decomp1, keyword.decomp2, keyword.decomp3, keyword.decomp4],
        color: keyword.color,
        enabled: keyword.enabled == 1,
      }));
      // console.log("formattedKeywords", formattedKeywords);

      // Dispatch an action to update the originalKeywords in the Redux store
      // dispatch(updateOriginalKeywords(data));
      dispatch(updateOriginalKeywords(formattedKeywords));

      return true;
    } catch (error) {
      console.error('Error fetching initial keywords:', error);
      // You can dispatch an error action or handle the error as needed
    }
  };
};

export const changeKeywordColor = (keywordId, newColor) => {
  return async (dispatch) => {
    console.log("changeKeywordColor", keywordId, newColor);
    try {
      const response = await fetch(`/api/keywords/change-color/${keywordId}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ color: newColor }),
      });

      if (!response.ok) {
        throw new Error('Failed to change keyword color');
      }

      // Dispatch an action to update the color in the Redux store
      dispatch(setOriginalKeywordColor(keywordId, newColor));
      dispatch(setKeywordColor(keywordId, newColor));

      return true;
    } catch (error) {
      console.error('Error changing keyword color:', error);
      // Handle the error as needed
    }

    return false;
  };
};


export const renameKeyword = (keywordId, newName) => {
  return async (dispatch) => {
    console.log("renameKeyword", keywordId, newName);
    try {
      const response = await fetch(`/api/keywords/rename/${keywordId}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: newName }),
      });

      if (!response.ok) {
        throw new Error('Failed to rename keyword');
      }

      // Dispatch an action to update the name in the Redux store
      dispatch(setOriginalKeywordName(keywordId, newName));
      dispatch(setKeywordName(keywordId, newName));

      return true;
    } catch (error) {
      console.error('Error renaming keyword:', error);
      // Handle the error as needed
    }

    return false;
  };
};


export const updateKeywordWithAutoDecomp = (keywordId, convIds) => {
  return async (dispatch) => {
    console.log("called autodecomp with keyword ID", keywordId);
    try {
      const response = await fetch(`/api/keywords/autodecomp/${keywordId}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        }
      });

      if (!response.ok) {
        throw new Error('Failed to autodecomp keyword color');
      }
      const data = await response.json();
      console.log(data.infer);

      // get data from server again
      // dispatch(fetchInitialKeywords());


      dispatch(fetchInitialKeywords()).then((success) => {
        if (success) {
          console.log("fetchInitialKeywords success");
          // dispatch(fetchConvRes(convIds.join("-")));

          // console.log("convIds", convIds);
          // dispatch(updateKeywords(convIds));
          // dispatch(removeKeywordFromTitle(keywordId));
          // dispatch(addKeywordToTitle(keywordId));

          dispatch(updateKewyword(keywordId));
        } else {
          console.log("fetchInitialKeywords failed");
        }
      });

      // If the keywordId exists in the originalKeywords, update decomp1~4
      // const originalKeywords = getState().originalKeywords;
      // const originalKeywords = keywords;
      // const keywordToUpdate = keywords.find((keyword) => keyword.id === keywordId);
      // if (keywordToUpdate) {
      //   console.log("updating decomp1~4 for keyword", keywordId);
      //   const { decomp1, decomp2, decomp3, decomp4 } = data.infer;

      //   // Dispatch actions to update decomp1~4 in the Redux store
      //   dispatch(editDecomp(keywordId, 0, decomp1));
      //   dispatch(editDecomp(keywordId, 1, decomp2));
      //   dispatch(editDecomp(keywordId, 2, decomp3));
      //   dispatch(editDecomp(keywordId, 3, decomp4));
      // }

      return true;

      // return true;
    } catch (error) {
      console.error('Error doing autodecomp:', error);
      // Handle the error as needed
    }

    return false;
  };
};


export const updateDecompOnServer = (keywordId, cellIndex, newText) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`/api/keywords/change-decomp/${keywordId}/${cellIndex}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ text: newText }),
      });

      if (!response.ok) {
        throw new Error('Failed to update decomposition on the server');
      }

      // Dispatch an action to update the decomposition in the Redux store
      dispatch(editDecomp(keywordId, cellIndex, newText));

      return true; // Indicate success
    } catch (error) {
      console.error('Error updating decomposition on the server:', error);
      // Handle the error as needed
      return false; // Indicate failure
    }
  };
};


// Asynchronous action using redux-thunk
export const fetchConvRes = (concatenatedIds) => {
  return async (dispatch) => {
    try {
      let tokens = concatenatedIds.split("-");
      if(tokens.length == 0) return false; // No keywords selected
      if(tokens.length == 1) return false; // Only one keyword selected

      const response = await fetch(`/api/convres/${concatenatedIds}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch conv res from server');
      }
  
      console.log("response", response)
      const data = await response.json();
      dispatch(setConvRes(data.convres.content));

      return true;  
    } catch (error) {
      // console.error('Error fetching ConvRes:', error);
      return false;
    }
  };
};


// Action creator for deleting convres data
export const deleteConvRes = (convKey) => async (dispatch, getState) => {
  try {
    const response = await fetch(`/api/convres/delete/${convKey}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    });

    return true;
  } catch (error) {
    // Dispatch an action in case of failure
    console.log("delete error", error);
    return false;
  }
};

// Asynchronous action using redux-thunk
export const saveConvRes = (convKey, content) => async (dispatch) => {
  try {
    let tokens = convKey.split("-");
    if(tokens.length == 0) return false; // No keywords selected
    if(tokens.length == 1) return false; // Only one keyword selected

    await fetch('/api/convres/save', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({ convkey: convKey, content }),
    });

    // dispatch(setConvRes(content));
    // toast.success('ConvRes saved successfully');
    return content;

  } catch (error) {
    console.error('Error saving ConvRes:', error);
    // toast.error('Error saving ConvRes');
    return null;
  }
};


// // Action Creators
export const setCombinations = (keys) => ({
  type: 'SET_COMBINATIONS',
  payload: keys,
});

// // Async Action Creator
// export const fetchCombinations = () => async (dispatch) => {
//   try {
//     const response = await axios.get('/api/convres/all', {
//       headers: {
//         'Content-Type': 'application/json',
//         // Authorization: `Bearer ${YOUR_TOKEN}`, // Replace YOUR_TOKEN with the actual token
//       },
//     });

//     // Assuming the response is an array of convres keys
//     dispatch(setCombinations(response.data));
//   } catch (error) {
//     console.error('Error fetching existing convres:', error);
//   }
// };

// redux/actions.js

// ... (existing code)

export const fetchCombinations = (token) => {
  return async (dispatch) => {
    try {
      const response = await fetch('/api/convres/all?includeConvnicks=true', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch combinations');
      }

      const data = await response.json();
      console.log("combinations", data)

      // Assuming your API response structure has an array of combinations
      const combinations = data;

      // Dispatch an action to update the Redux store with the fetched combinations
      dispatch(setCombinations(combinations)); // You need to create setCombinations action


      return true; // Indicate success
    } catch (error) {
      console.error('Error fetching combinations:', error);
      return false; // Indicate failure
    }
  };
};


export const downloadData = () => {
  return async (dispatch) => {
    try {
      const response = await fetch('/api/users/download_data', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to download data');
      }

      // Extract filename from Content-Disposition header
      const contentDisposition = response.headers.get('Content-Disposition');
      const filenameMatch = contentDisposition && contentDisposition.match(/filename="(.+?)"/);
      const filename = filenameMatch ? filenameMatch[1] : 'downloaded_file';

      // Convert the response blob into a Blob object
      const blob = await response.blob();


      // Create a temporary link to trigger the download
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'conv_data.xlsx'; // You can change the filename if needed
      link.click();

      // Create an object URL for the Blob
      // const blobUrl = window.URL.createObjectURL(blob);

      // // Dispatch an action to handle the download
      // dispatch(handleDownloadData({ blobUrl, filename }));
    } catch (error) {
      console.error('Error downloading data:', error);
      // You can dispatch an error action or handle the error as needed
    }
  };
};


export const setAIMode = (isAIMode) => ({
  type: 'SET_AI_MODE',
  payload: isAIMode,
});