// ConvTable.js
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setConvRes } from './redux/actions';
import KeywordTitle from './KeywordTitle';
import DecompTable from './DecompTable';
import ConvRes from './ConvRes';

const ConvTable = () => {

  return (
    <div>
      <KeywordTitle />
      <DecompTable />
      <ConvRes />
    </div>
  );
};

export default ConvTable;
