// CombinationButton.js
import React from 'react';
import { useDispatch } from 'react-redux';
import { updateKeywords } from './redux/actions';

const CombinationButton = ({ combination }) => {
  const dispatch = useDispatch();
  
  const buttonStyle = {
    margin: '0 5px',
    padding: '1px 2px', // Adjusted padding for a smaller size
    cursor: 'pointer',
    outline: 'none',
    fontSize: '0.8em', // Adjusted font size for a smaller text
  };

  // Join convnicks with ' & ' in between
  // console.log(combination);
  const convnicksText = combination.convnicks.join(' · '); //	U+00B7 MIDDLE DOT

  const handleClick = () => {
    // Retrieve combination.convkey and split with '-'
    const keyIds = combination.convkey.split('-');

    // Update state.keywords with the extracted keyIds
    dispatch(updateKeywords(keyIds));
  };

  return (
    <button style={buttonStyle} onClick={handleClick}>
      {convnicksText}
    </button>
  );
};

export default CombinationButton;
