// KeywordButton.js
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateKeywordWithAutoDecomp, setOriginalKeywordEnabled, renameKeyword, changeKeywordColor, addKeywordToTitle, removeKeywordFromTitle, removeOriginalKeyword, setKeywordColor, setKeywordName, setOriginalKeywordColor, setOriginalKeywordName} from './redux/actions';
import { toast } from 'react-toastify';
import { generatePastelColor } from './util/Colors';

const KeywordButton = ({ keyword, keywordsMode, setAllModesOff }) => {
  const dispatch = useDispatch();
  const keywords = useSelector((state) => state.keywords);

  const buttonStyle = {
    backgroundColor: keyword.color || '#e0e0e0', // Use keyword color or default color
    color: '#333',
    padding: '5px 10px',
    margin: '5px',
    cursor: 'pointer',
    fontWeight: 'bold',
  };


  const getKeywordIdsWithAppend = (newId) => {
    // Sort keywords by id in ascending order

    const new_keywords = keywords.map((keyword) => keyword.id);
    if(!new_keywords.includes(newId)) new_keywords.push(newId);
    new_keywords.sort((a, b) => a - b);

    // const sortedKeywords = keywords.sort((a, b) => a.id - b.id);

    // Get an array of keyword names sorted by id
    // const sortedIds = sortedKeywords.map((keyword) => keyword.id);

    // Concatenate sorted keyword names with '-'
    let ids = new_keywords.join('-');
    return ids;
  }

  const handleClick = () => {
    const keywordExists = keywords.some((kw) => kw.id === keyword.id);

    switch (keywordsMode) {
      case 'default':
        if (keywordExists) {
          // Keyword already exists, show a message or take appropriate action
          toast.warning(`Keyword "${keyword.name}" already exists!`);
        } else {
          // Dispatch an action to add the keyword to the Redux store
          dispatch(addKeywordToTitle(keyword.id));
          // Notify that the button is pressed and added
          // toast.success(`Keyword "${keyword.name}" pressed and added! DBG:${JSON.stringify(keyword)}`);
        }
        break;

      case 'remove':
        // Dispatch an action to remove the keyword from the Redux store
        dispatch(removeKeywordFromTitle(keyword.id));
        // Notify that the button is pressed and removed
        // toast.success(`Keyword "${keyword.name}" pressed and removed! DBG:${JSON.stringify(keyword)}`);
        break;
      

      case 'delete':

        // Use window.confirm to prompt the user for confirmation
        const confirmed = window.confirm(`Are you sure you want to delete the selected keyword '${keyword.name}'?`);

        // Return early if the user doesn't confirm
        if (!confirmed) {
          // toast.info(`Keyword [${keyword.name}] not deleted`);
          toast.info(`분해대상 [${keyword.name}] 가 삭제되지않았습니다`);
          break;
        }

        // dispatch(removeKeywordFromTitle(keyword.id));
        // dispatch(removeOriginalKeyword(keyword.id));

        //toggle enabled
        dispatch(removeKeywordFromTitle(keyword.id));
        dispatch(setOriginalKeywordEnabled(keyword.id, false));

        
        // toast.success(`Changed deleted keyword [${keyword.name}]`);
        toast.info(`융합대상 [${keyword.name}] 를 삭제했습니다`);
        break;

      case 'recolor':
        const newColor = generatePastelColor();
        dispatch(changeKeywordColor(keyword.id, newColor)).then((success) => {
          if(success) {
            // toast.success(`Changed color for keyword [${keyword.name}]`);
            toast.success(`분해대상 [${keyword.name}] 의 색상을 바꿨습니다`);
          }else{
            toast.warning(`Failed to change color for keyword [${keyword.name}]`);
          }
        });
        // dispatch(setKeywordColor(keyword.id, newColor));
        // dispatch(setOriginalKeywordColor(keyword.id, newColor));
        // toast.success(`Changed color for keyword [${keyword.name}]`);
        break;

      case 'rename':
        const newName = prompt('분해대상의 새로운 이름을 알려주세요:', keyword.name);
        if(null == newName) break;
        console.log("rename", keyword.id, newName.trim());
        if (newName !== null && newName.trim() !== '') {
          dispatch(renameKeyword(keyword.id, newName)).then((success) => {
            if(success) {
              // toast.success(`Changed name for keyword [${keyword.name}] to [${newName}]`);
              toast.success(`분해대상의 이름을 [${keyword.name}] 에서 [${newName}] 로 바꿨습니다`);
            }else{
              toast.warning(`Failed to change name for keyword [${keyword.name}] to [${newName}]`);
            }
          })
          
        } else {
          toast.warning('New name cannot be empty');
        }
        break;

      
      case 'autodecomp':
        if (keywordExists) {
          // Keyword already exists, show a message or take appropriate action
          // toast.warning(`Keyword "${keyword.name}" already exists!`);
        } else {
          // Dispatch an action to add the keyword to the Redux store
          dispatch(addKeywordToTitle(keyword.id));
          // Notify that the button is pressed and added
          // toast.success(`Keyword "${keyword.name}" pressed and added! DBG:${JSON.stringify(keyword)}`);
        }

        const convIdStr = getKeywordIdsWithAppend(keyword.id);
        console.log("convIdStr", convIdStr);
        // const toastId = toast.info(`Decomposing keyword [${keyword.name}] with AI. It may take a few seconds. Please wait...`, {
        const toastId = toast.info(`인공지능(ChatGPT)로 [${keyword.name}]을(를) 분해하고 있습니다. 시간이 좀 걸릴 수 있습니다...`, {
          autoClose: 20000, // Set the time (in milliseconds) after which the toast will automatically close
        });
        dispatch(updateKeywordWithAutoDecomp(keyword.id, convIdStr)).then((success) => {
          toast.dismiss(toastId);
          if(success) {
            // toast.success(`Decomposed keyword [${keyword.name}] with AI`);
            toast.success(`인공지능(ChatGPT)로 [${keyword.name}]을(를) 분해했습니다`);
          }else{
            toast.warning(`Failed to change color for keyword [${keyword.name}]`);
          }
        });
        // dispatch(setKeywordColor(keyword.id, newColor));
        // dispatch(setOriginalKeywordColor(keyword.id, newColor));
        // toast.success(`Changed color for keyword [${keyword.name}]`);
        break;

      default:
        break;
    }

    //reset to default
    if(setAllModesOff){
      setAllModesOff();
    }
  };

  return (
    <button style={buttonStyle} onClick={handleClick}>
      {keyword.name}
    </button>
  );
};

export default KeywordButton;
