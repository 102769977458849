// reducers.js
const initialState = {
  originalKeywords: [],
  keywords: [],
  convRes: '',
  combinations: [],
  isAIMode: false,
};

const rootReducer = (state = initialState, action) => {
  console.log('action', action.type, action.payload);
  // let keywordId, color, name;
  switch (action.type) {
    // case 'SET_KEYWORD_NAME':
    //   return { ...state, keywordName: action.payload };
    case 'ADD_KEYWORD_TO_TITLE':
      const addedKeyword = state.originalKeywords.find((kw) => kw.id === action.payload);
      return {
        ...state,
        keywords: [...state.keywords, addedKeyword],
      };
    case 'REMOVE_KEYWORD_FROM_TITLE':
      return {
        ...state,
        keywords: state.keywords.filter((kw) => kw.id !== action.payload),
      };
    case 'UPDATE_KEYWORD':
      const updatedKeywordData = state.originalKeywords.find((kw) => kw.id === action.payload);
      console.log('updatedKeywordData', updatedKeywordData);
      const existingKeywords = state.keywords;
      console.log('updaexistingKeywordstedKeywordData', existingKeywords);
      const newKwds = []
      for(let i=0; i<existingKeywords.length; i++){
        if(existingKeywords[i].id === action.payload){
          newKwds.push(updatedKeywordData);
        }else{
          newKwds.push(existingKeywords[i]);
        }
      }

      //update the corresponindg existingKeywords data with updatedKeywordData
      // const updKwds = existingKeywords.map((kw) => {
      //   if (kw.id === action.payload) {
      //     kw = updatedKeywordData;
      //   }
      // });
      //return the updated state
      return {
        ...state,
        keywords: newKwds,
      };
    case 'REMOVE_ORIGINAL_KEYWORD':
      return {
        ...state,
        originalKeywords: state.originalKeywords.filter((kw) => kw.id !== action.payload),
      };
    case 'SET_ORIGINAL_KEYWORD_ENABLED':
      return {
        ...state,
        originalKeywords: state.originalKeywords.map((keyword) =>
          keyword.id === action.payload.keywordId
            ? { ...keyword, enabled: action.payload.enabled }
            : keyword
        ),
      };

    case 'SET_CONV_RES':
      return { ...state, convRes: action.payload };
    case 'EDIT_DECOMP':
      const { keywordId, cellIndex, newText } = action.payload;
      const updatedKeywords = [...state.keywords];
      const keywordIndex = updatedKeywords.findIndex((keyword) => keyword.id === keywordId);

      if (keywordIndex !== -1) {
        updatedKeywords[keywordIndex].decomps[cellIndex] = newText;
      }

      return {
        ...state,
        keywords: updatedKeywords,
      };
    case 'SET_KEYWORD_COLOR':
      let { keywordId: colorKeywordId, color } = action.payload;
      const updatedKeywordsColor = state.keywords.map((keyword) => {
        if (keyword.id === colorKeywordId) {
          return { ...keyword, color };
        }
        return keyword;
      });
      return {
        ...state,
        keywords: updatedKeywordsColor,
      };
    case 'SET_ORIGINAL_KEYWORD_COLOR':
      let { keywordId: origColorKeywordId, color: origColor } = action.payload;
      console.log('SET_ORIGINAL_KEYWORD_COLOR', origColorKeywordId, origColor);
      const updatedOrignKeywordsColor = state.originalKeywords.map((originalKeyword) => {
        if (originalKeyword.id === origColorKeywordId) {
          return { ...originalKeyword, color:origColor };
        }
        return originalKeyword;
      });
      return {
        ...state,
        originalKeywords: updatedOrignKeywordsColor,
      };
      
    case 'SET_KEYWORD_NAME':
      let { keywordId: nameKeywordId, name } = action.payload;
      console.log('SET_KEYWORD_NAME', nameKeywordId, name);
      const updatedKeywordsName = state.keywords.map((keyword) => {
        if (keyword.id === nameKeywordId) {
          return { ...keyword, name };
        }
        return keyword;
      });
      return {
        ...state,
        keywords: updatedKeywordsName,
      };


    case 'SET_ORIGINAL_KEYWORD_NAME':
      let { keywordId: orignNameKeywordId, name: origName } = action.payload;
      console.log('SET_ORIGINAL_KEYWORD_NAME', orignNameKeywordId, origName);
      const updatedOrigKeywordsName = state.originalKeywords.map((originalKeyword) => {
        if (originalKeyword.id === orignNameKeywordId) {
          console.log('SET_ORIGINAL_KEYWORD_NAME', originalKeyword.id, orignNameKeywordId, origName);
          return { ...originalKeyword, name:origName };
        }
        return originalKeyword;
      });
      return {
        ...state,
        originalKeywords: updatedOrigKeywordsName,
      };
    
    case 'UPDATE_ORIGINAL_KEYWORDS':
      return {
        ...state,
        originalKeywords: action.payload,
      };
    case 'UPDATE_KEYWORDS':
      return {
        ...state,
        keywords: action.payload,
      };
    case 'ADD_KEYWORD':
      return {
        ...state,
        originalKeywords: [...state.originalKeywords, action.payload],
      };

    case 'SET_COMBINATIONS':
      return {
        ...state,
        combinations: action.payload,
      };
    case 'SET_AI_MODE':
      return {
        ...state,
        isAIMode: action.payload,
      };
    default:
      return state;
  }
};

export default rootReducer;
