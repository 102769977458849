import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Home from './Home';
import Register from './Register';
import HowToUse from './HowToUse';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import GlobalStyle from './GlobalStyles';
import { ToastContainer } from 'react-toastify';
import Layout from './Layout'; // Import the Layout component
import { setAIMode } from './redux/actions';
import { calcAiEnabled } from './util/Subdomain';

store.dispatch(setAIMode(calcAiEnabled()));

const ErrorPage = () => {
  return (
    <div>
      Invalid URL. What are you doing? Go back to the main page.
    </div>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <GlobalStyle />
        <Routes>
            {/* Wrap each page with the Layout component */}
            <Route path="/myboard" element={<Layout><App /></Layout>} />
            <Route path="/" element={<Layout><Navigate to="/login" /></Layout>} />
            <Route path="/login" element={<Layout><Home /></Layout>} />
            <Route path="/register" element={<Layout><Register /></Layout>} />

            <Route path="/howtouse" element={<Layout><HowToUse /></Layout>} />
            <Route path="*" element={<ErrorPage />} /> 
          
        </Routes>
      </Router>
    </Provider>
    <ToastContainer position="bottom-right" autoClose={5000} />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
